$(document).ready(function() {

    "use strict";

    //$('.menu__item:has(.submenu)').addClass('menu-dropdown-icon');
    //Checks if li has sub (ul) and adds class for toggle icon - just an UI

    //$('.menu__item:not(:has(.submenu))').addClass('normal-sub');
    //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

   // $(".menu").before("<a href=\"#\" class=\"menu-mobile\"><div class=\"menu-bar1\"></div><div class=\"menu-bar2\"></div><div class=\"menu-bar3\"></div></a>");

    //Adds menu-mobile class (for mobile toggle menu) before the normal menu
    //Mobile menu is hidden if width is more then 959px, but normal menu is displayed
    //Normal menu is hidden if width is below 959px, and jquery adds mobile menu
    //Done this way so it can be used with wordpress without any trouble

    $('.menu__item').hover(function(e) {
      if ($(window).width() > 991) {
        //$(this).children('.submenu').stop(true, false).fadeToggle(150);
        $(this).children('.submenu').stop(true, false).toggle();
        $(this).toggleClass('active');
        e.preventDefault();
      }
    });
    //If width is more than 943px dropdowns are displayed on hover

    /*$('.menu__item').click(function() {
      if ($(window).width() <= 992) {
        $(this).children('.submenu').fadeToggle(150);
        $(this).siblings('.submenu').toggle();
        //$(this).children('.submenu').fadeToggle;
      }
    });*/
    $('.menu__item').click(function() {
      if ($(window).width() <= 992) {
        $(this).toggleClass("submenu__active");
        $(this).siblings().removeClass("submenu__active");
      }
    });
    //If width is less or equal to 943px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)

    $(".menu-mobile").click(function(e) {
      //$(this).siblings('.menu').toggleClass('show-on-mobile');
      //$('.menu__footer').toggleClass('show-on-mobile');
      $('body').toggleClass('menu-opened')
      e.preventDefault();
    });
    //when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)

  });
