// Upcoming events
if (typeof Swiper !== 'undefined') {
    var upcomingSwiper = new Swiper("#upcoming-events", {
        slidesPerView: 1.2,
        spaceBetween: 10,
        speed: 600,
        breakpoints: {
            640: {
                slidesPerView: 2.6,
                slidesPerGroup: 2,
                speed: 600,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                speed: 900,
            },
            1170: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                speed: 800,
            },
        },
        navigation: {
            nextEl: ".upcoming-button-next",
            prevEl: ".upcoming-button-prev",
        },
    });
}
// end Upcoming events

// Recommended events
if (typeof Swiper !== 'undefined') {
    var recommendedSwiper = new Swiper("#recommended-events", {
        slidesPerView: 1.2,
        spaceBetween: 10,
        breakpoints: {
            640: {
                slidesPerView: 2.6,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            1170: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
        },
        navigation: {
            nextEl: ".recommended-button-next",
            prevEl: ".recommended-button-prev",
        },
    });
}
// end Recommended events
